import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Boxplot, computeBoxplotStats } from 'react-boxplot';
import { average, extent, interquartileRange, median } from 'simple-statistics';

enum ModeEnum {
  input,
  done,
}

function App() {
  return (
    <div className="flex flex-col h-full items-center justify-center text-white bg-gradient-to-br from-gray-600 via-teal-700 to-gray-800">
      <div className="max-w-screen-md w-full">
        <Calculator />
      </div>
    </div>
  );
}

function Calculator() {
  const [mode, setMode] = useState<ModeEnum>(ModeEnum.input);
  const [nums, setNums] = useState<number[]>([]);

  const add = useCallback(
    (num: number) => {
      setNums([...nums, num]);
    },
    [nums, setNums]
  );

  const done = useCallback(() => {
    setMode(ModeEnum.done);
  }, [setMode]);

  const reset = useCallback(() => {
    setNums([]);
    setMode(ModeEnum.input);
  }, [setNums, setMode]);

  if (mode === ModeEnum.input) {
    return <NumInput add={add} done={done} reset={reset} total={nums.length} />;
  }

  return <StatPresenter values={nums} reset={reset} />;
}

function NumInput({
  add,
  done,
  reset,
  total,
}: {
  add: (num: number) => void;
  done: () => void;
  reset: () => void;
  total: number;
}) {
  const [num, setNum] = useState<number>(0);
  const [formatted, setFormatted] = useState<string>('');

  const set = useCallback(
    (values: NumberFormatValues) => {
      setNum(values.floatValue || 0);
      setFormatted(values.formattedValue);
    },
    [setNum]
  );

  const next = useCallback(() => {
    if (num == null) return;
    add(num);
    setNum(0);
    setFormatted('');
  }, [add, num, setNum]);

  return (
    <div className="grid gap-4 w-full">
      <h2 className="text-center text-xl font-bold">Input #{total + 1}</h2>
      <div>
        <NumberFormat
          className="p-3 text-lg text-black w-full"
          value={formatted}
          onValueChange={set}
          thousandSeparator={true}
          placeholder="1,000,000"
        />
      </div>
      <div className="grid gap-4">
        <button
          className="p-4 rounded-md bg-red-500 hover:bg-red-600"
          onClick={reset}
        >
          Reset
        </button>
        <button
          className="p-4 rounded-md bg-green-500 hover:bg-green-600 disabled:bg-gray-500"
          onClick={done}
          disabled={total < 1}
        >
          Done
        </button>
        <button
          className="p-4 rounded-md bg-blue-500 hover:bg-blue-600 disabled:bg-gray-500"
          onClick={next}
          disabled={num <= 0}
        >
          Next
        </button>
      </div>
    </div>
  );
}

function StatPresenter({
  values,
  reset,
}: {
  values: number[];
  reset: () => void;
}) {
  const [min, max] = extent(values);
  const interquartile = interquartileRange(values);

  const plotStyleProps = {
    tickStyle: { stroke: 'white' },
    whiskerStrokeWidth: 1,
    whiskerStyle: { stroke: 'white' },
    boxStyle: { stroke: 'white', fill: 'white' },
    medianStrokeWidth: 2,
    medianStyle: { stroke: 'black' },
    outlierRadius: 2.5,
    outlierStyle: { stroke: 'white', fill: 'white' },
  };

  const numberFormat = new Intl.NumberFormat();

  const stats: [string, number][] = [
    ['Min', min],
    ['Median', median(values)],
    ['Average', average(values)],
    ['Max', max],
  ];

  const [width, setWidth] = useState(Math.min(768, window.innerWidth));

  useEffect(() => {
    const resizeListener = () => {
      setWidth(Math.min(768, window.innerWidth));
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [setWidth]);

  return (
    <div className="grid gap-4">
      <h2 className="text-center text-xl font-bold">Result</h2>
      <Boxplot
        width={width}
        height={20}
        min={min - interquartile}
        max={max + interquartile}
        strokeWidth={1}
        orientation="horizontal"
        stats={computeBoxplotStats(values)}
        {...plotStyleProps}
      />
      <dl>
        {stats.map(([name, value]) => (
          <div
            key={name}
            className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt className="text-sm font-medium text-gray-500">{name}</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right text-lg">
              {numberFormat.format(value)}
            </dd>
          </div>
        ))}
      </dl>

      <button
        onClick={reset}
        className="p-4 rounded-md bg-red-500 hover:bg-red-600"
      >
        Reset
      </button>
    </div>
  );
}

export default App;
